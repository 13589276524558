
import { Vue, Component, Prop } from 'vue-property-decorator';

import Divider from '@/modules/settings/components/divider/Divider.vue';
import ListItem from '@/modules/settings/components/list/list-item/ListItem.vue';
import AddListItem from '@/modules/settings/components/list/list-item/AddListItem.vue';

@Component({
  name: 'List',
  components: { AddListItem, Divider, ListItem },
})
export default class List extends Vue {
  @Prop() items: Record<string, string>;
  @Prop() addFieldButtonValue: string;
  @Prop({ default: false }) optsButton: boolean;
  @Prop({ default: 'inline' }) addFieldType: string;
  @Prop({ default: null }) contentType: string;
  @Prop() newItem: {name: string, color?: string, tags?: []}
}

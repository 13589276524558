<template>
	<div class="delete-context-wrp" @click.stop="closeDeleteContext()">
		<div class="delete-context" @click.stop>
			<h4 class="delete-context__label">
				<slot />
			</h4>
			<div class="delete-context__actions" :class="{'center': center}">
				<div class="delete" @click.stop="deleteItem()" tabindex="0">
					Удалить
				</div>
				<div class="cancel" @click.stop="closeDeleteContext()" tabindex="0">
					Нет, отмена
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'DeleteContext',

	props: ['center'],

	methods:
	{
		deleteItem()
		{
			this.$emit('delete');
		},
		closeDeleteContext()
		{
			this.$emit('close');
		},
	},
}
</script>
<style lang="scss">
.delete-context-wrp
{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100vh;
	width: 100%;
	z-index: 999999;
	background-color: rgba(0, 0, 0, 0.5);
}
.delete-context
{
	position: absolute;
	z-index: 99999;
	background-color: #191919;
	width: 240px;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 26px 36px 36px 36px;
	left: 50%;
	border-radius: 8px;
	border: 1px solid #913333;
	h4
	{
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		color: #FFFFFF;
	}
	&__item
	{
		border: 0.7px solid rgba(255, 255, 255, 0.3);
		border-radius: 8px;
		padding: 12px 60px 12px 15px;
		margin-bottom: 20px;
		&__name
		{
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: #FFFFFF;
		}
	}
	&__actions
	{
		display: flex;
		align-items: center;

		.delete
		{
			background: rgba(216, 67, 67, 0.8);
			border-radius: 20.5px;
			font-weight: 400;
			font-size: 12px;
			padding: 14px 25px;
			line-height: 14px;
			text-align: center;
			color: #FFFFFF;
			margin-right: 26px;
			cursor: pointer;
		}
		.cancel
		{
			font-style: normal;
			font-weight: 400;
			font-size: 11px;
			line-height: 144%;
			color: rgba(255, 255, 255, 0.8);
			cursor: pointer;
		}
		&.center { justify-content: center; }
	}
}
.delete-context
{
	border: 0.7px solid #9E4835;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.7);
	border-radius: 19px;
	min-width: 350px;
	width: 100%;
	max-width: 420px;
	padding: 26px 36px 26px 36px;;
	.delete-context__label
	{
		margin-top: 0;
	}
	.delete-context__actions
	{
		div
		{
			font-size: 14px;
		}
		.delete
		{
			background: #9E4835;
			border-radius: 19px;
		}
	}
	.delete-context__subtitle
	{
		margin-top: 10px;
		display: block;
		font-weight: 300;
		font-size: 14px;
		line-height: 17px;
		color: rgba(255, 255, 255, 0.5);
	}
}
</style>

import { Component, Emit, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import Colorizable from '@/mixins/Colorizable';
import DetectClickOutside from '@/mixins/DetectClickOutside';
import ClassListComposer from '@/mixins/ClassListComposer';

import Button from '@/components/ui/button';
import Icon from '@/components/ui/icon/Icon.vue';

@Component({
  components: {
    Button,
    Icon,
  },
})
export default class PopupDialog extends mixins(Colorizable, ClassListComposer, DetectClickOutside) {
  public baseClass = 'popup-dialog';
  public isExpanded = false;

  @Prop({ required: true }) message: string;
  @Prop() buttonIcon: string;

  @Emit('open')
  open() {
    this.isExpanded = true;
  }

  @Emit('close')
  close() {
    this.isExpanded = false;
  }

  @Emit('cancel')
  cancel() {
    this.close();
  }

  @Emit('confirm')
  confirm() {
    this.close();
  }

  mounted() {
    this.$on('clickOutside', this.cancel);
  }

  beforeDestroy() {
    this.$off('clickOutside', this.cancel);
  }
}


import { Component, Prop, Emit, Ref } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import DetectClickOutside, { ClickOutsideEvent } from '@/mixins/DetectClickOutside';

import KeydownEnter from '@/assets/images/keydown-enter.svg';
import { KeyCodes } from '@/services/helpers/constants';

@Component({})
export default class EditItem extends mixins(DetectClickOutside) {
  innerValue: string;
  eventEmited = false;
  keydownEnterIcon = KeydownEnter;

  @Prop({ default: '' }) value: string;
  @Ref() readonly inputElement!: HTMLElement;

  onKeyDown(e: KeyboardEvent) {
    if (e.key === KeyCodes.Escape) this.onBlur(e);
    if (e.key === KeyCodes.Enter) this.onSubmit(e);
  }

  preventDubbleEmit() {
    if (!this.eventEmited) {
      this.eventEmited = true;
      return false;
    } else {
      return true;
    }
  }

  // Emitters

  @Emit('submit')
  onSubmit(e: Event) {
    if (this.preventDubbleEmit()) return;
    e.stopPropagation();
    return this.innerValue;
  }

  @Emit('cancel')
  onBlur(e: Event) {
    if (this.preventDubbleEmit()) return;
    e.stopPropagation();
    return this.innerValue;
  }

  // Hooks

  beforeMount() {
    this.innerValue = this.value;
  }

  mounted() {
    this.inputElement.focus();
    this.$on(ClickOutsideEvent, this.onBlur);
  }

  beforeDestroy() {
    this.$off(ClickOutsideEvent, this.onBlur);
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator';

import EditListItem from '@/modules/settings/components/list/list-item/EditListItem.vue';

import AddIcon from '@/assets/images/add.svg';
import KeydownEnter from '@/assets/images/keydown-enter.svg';
import Editor from '@/modules/settings/views/directions/components/Editor.vue';

@Component({
    name: 'AddFieldItem',
    components: {
        EditListItem,
        Editor,
    },
})
export default class AddFieldItem extends Vue {
    @Prop({ default: '' }) value: string;
    @Prop({ default: 'inline' }) addFieldType: string;
    @Prop({ default: null }) contentType: string;
    @Prop() newItem: {name: string, color?: string, tags?: []};

    public addIcon = AddIcon;
    public keydownEnterIcon = KeydownEnter;
    public showAddField = false;

    onSubmit(value: string) {

        
        this.showAddField = false;
        this.$parent.$emit('add', value);
    }
}
